import Script from 'next/script'

export const Analytics = () => {
  return (
    <>
      <Script
        strategy="lazyOnload"
        async
        defer
        src={`https://tools.luckyorange.com/core/lo.js?site-id=b5879097`}
      />
    </>
  )
}
