import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAuth } from '@/hooks/useAuth'
import { PageLoader } from '../spinners/PageLoader'
import { useStore } from '@/hooks/useStore'
import { observer } from 'mobx-react-lite'

export const AuthGuard: FC = observer(props => {
  const { children } = props
  const { user, loading } = useAuth()
  const router = useRouter()
  const { redirect } = useStore()

  useEffect(() => {
    if (!loading) {
      if (!user || user.isAnonymous) {
        redirect.setUrl(router.asPath)
        router.push('/signin')
      }
    }
  }, [loading, router, user])

  if (loading) {
    return <PageLoader />
  }

  if (user && !user.isAnonymous) {
    return <>{children}</>
  }

  /* otherwise don't return anything, will do a redirect from useEffect */
  return null
})
