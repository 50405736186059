import { types, getParent } from 'mobx-state-tree'

export const RedirectStore = types
  .model('RedirectStore', {
    url: types.maybe(types.string),
  })
  .views(self => ({
    get root() {
      return getParent(self)
    },
  }))
  .actions(self => ({
    setUrl(url: string) {
      self.url = url
    },

    clear() {
      self.url = ''
    },
  }))
