import { FC, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { PageLoader } from '../spinners/PageLoader'
import { observer } from 'mobx-react-lite'
import { trpc } from '@utils/trpc'
import { useAuth } from '@hooks/useAuth'
import { inferQueryOutput } from '@utils/trpc'

const productsEnabled = (
  products: inferQueryOutput<'product.byUID'> | undefined,
) => {
  return (
    products?.atsIntegration ||
    products?.hrisIntegration ||
    products?.sourceAndScreen ||
    (typeof window !== undefined && window.localStorage.getItem('skip-config'))
  )
}

export const ProductGuard: FC = observer(({ children }) => {
  const { user } = useAuth()
  const router = useRouter()
  const {
    data: products,
    isLoading: isLoadingProduct,
    refetch: refetchGetProduct,
  } = trpc.useQuery(['product.byUID'], {
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    retryOnMount: false,
    context: {
      skipBatch: true,
    },
    enabled: Boolean(user),
    onSuccess: data => {
      if (!productsEnabled(data)) {
        router.push('/signup/connect')
        return
      }
      // Redirect to dashboard from root if there is merge product,
      // but no source and screen. Dashboard has empty state for this case
      if (
        (data.atsIntegration || data.hrisIntegration) &&
        !data.sourceAndScreen &&
        router.route === '/'
      ) {
        router.push('/dashboard')
      }
    },
  })

  useEffect(() => {
    if (user) {
      refetchGetProduct()
    }
  }, [user])

  if (isLoadingProduct) {
    return <PageLoader />
  }

  if (productsEnabled(products)) {
    return <>{children}</>
  }

  /* otherwise don't return anything, will do a redirect from useEffect */
  return null
})
