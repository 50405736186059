import * as Sentry from '@sentry/nextjs'
const { version } = require('./package.json')

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const ENVIRONMENT = process.env.NODE_ENV as string

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 0,
  release: version,
  environment: ENVIRONMENT,
  attachStacktrace: true,

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
