import 'src/styles/globals.css'
import type { AppProps } from 'next/app'
import { httpBatchLink } from '@trpc/client/links/httpBatchLink'
import { loggerLink } from '@trpc/client/links/loggerLink'
import { httpLink } from '@trpc/client/links/httpLink'
import { splitLink } from '@trpc/client/links/splitLink'
import { withTRPC } from '@trpc/next'
import { NextPage } from 'next'
import { AppType } from 'next/dist/shared/lib/utils'
import { AppRouter } from '@api/routers/_app'
import superjson from 'superjson'
import { Analytics } from '@/utils/analytics'
import { auth } from '@/utils/firebase'
import { AuthProvider } from '@/contexts/auth'
import { AuthGuard } from '@/components/auth/AuthGuard'
import { useStore } from '@/hooks/useStore'
import { Provider as RootStoreProvider } from 'mobx-react'
import { getBaseUrl } from '@/config/getBaseUrl'
import { ProductGuard } from '@/components/products/ProductGuard'

export type NextApplicationPage<P = any, IP = P> = NextPage<P, IP> & {
  requireAuth?: boolean
  requireProductGuard?: boolean
}

const MyApp = ((props: AppProps) => {
  const {
    Component,
    pageProps,
  }: { Component: NextApplicationPage; pageProps: any } = props
  const store = useStore(pageProps.initialState)

  return (
    <>
      <Analytics />
      {/* <ReactQueryDevtools /> */}
      <AuthProvider>
        <RootStoreProvider store={store}>
          {/* if requireAuth property is present - protect the page */}
          {Component.requireAuth ? (
            <AuthGuard>
              {Component.requireProductGuard ? (
                <ProductGuard>
                  <Component {...pageProps} />
                </ProductGuard>
              ) : (
                <Component {...pageProps} />
              )}
            </AuthGuard>
          ) : (
            // public page
            <Component {...pageProps} />
          )}
        </RootStoreProvider>
      </AuthProvider>
    </>
  )
}) as AppType

export default withTRPC<AppRouter>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  config({ ctx }) {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    return {
      /**
       * @link https://trpc.io/docs/links
       */
      links: [
        // adds pretty logs to your console in development and logs errors in production
        loggerLink({
          enabled: opts =>
            process.env.NODE_ENV === 'development' ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        splitLink({
          condition(op) {
            // check for context property `skipBatch`
            return op.context.skipBatch === true
          },
          true: httpLink({
            url: `${getBaseUrl()}/api/trpc`,
          }),
          false: httpBatchLink({
            url: `${getBaseUrl()}/api/trpc`,
          }),
        }),
      ],
      /**
       * @link https://trpc.io/docs/data-transformers
       */
      transformer: superjson,
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      async headers() {
        const accessToken = await auth.currentUser?.getIdToken()
        return {
          Authorization: `Bearer ${accessToken}`,
        }
      },
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: false,
  // /**
  //  * Set headers or status code when doing SSR
  //  */
  // responseMeta({ clientErrors }) {
  //   if (clientErrors.length) {
  //     // propagate http first error from API calls
  //     return {
  //       status: clientErrors[0].data?.httpStatus ?? 500,
  //     }
  //   }

  //   // for app caching with SSR see https://trpc.io/docs/caching
  //   // cache full page for 1 day + revalidate once every second
  //   const ONE_DAY_IN_SECONDS = 60 * 60 * 24
  //   return {
  //     'Cache-Control': `s-maxage=1, stale-while-revalidate=${ONE_DAY_IN_SECONDS}`,
  //   }
  // },
})(MyApp)
