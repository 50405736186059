import {
  applySnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from 'mobx-state-tree'
import { RedirectStore } from './redirect-store'

let store: IStore | undefined

export const RootStore = types.model('RootStore', {
  redirect: types.optional(RedirectStore, {}),
})

export type IStore = Instance<typeof RootStore>
export type IStoreSnapshotIn = SnapshotIn<typeof RootStore>
export type IStoreSnapshotOut = SnapshotOut<typeof RootStore>

export function initializeStore(snapshot = null) {
  const _store = store ?? RootStore.create({})

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.tsx` and `pages/ssr.tsx` for more details
  if (snapshot) {
    applySnapshot(_store, snapshot)
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return store
}
