import type { Auth, User } from 'firebase/auth'
import type { FC } from 'react'
import { auth } from '@utils/firebase'
import { createContext } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'

export const AuthContext = createContext<
  | {
      auth: Auth
      loading: boolean
      user: User | null | undefined
      error: Error | undefined
    }
  | undefined
>(undefined)

AuthContext.displayName = 'AuthContext'

export const AuthProvider: FC = props => {
  const { children } = props
  const [user, loading, error] = useAuthState(auth)

  const value = {
    user,
    error,
    auth,
    loading,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
