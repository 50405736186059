import { createReactQueryHooks, createTRPCClient } from '@trpc/react'
import type { inferProcedureOutput } from '@trpc/server'
// ℹ️ Type-only import:
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-8.html#type-only-imports-and-export
import type { AppRouter } from '@api/routers/_app'
import { auth } from '@/utils/firebase'
import { getBaseUrl } from '@/config/getBaseUrl'

/**
 * A set of strongly-typed React hooks from your `AppRouter` type signature with `createReactQueryHooks`.
 * @link https://trpc.io/docs/react#3-create-trpc-hooks
 */
export const trpc = createReactQueryHooks<AppRouter>()

// export const transformer = superjson;
/**
 * This is a helper method to infer the output of a query resolver
 * @example type HelloOutput = inferQueryOutput<'hello'>
 */
export type inferQueryOutput<
  TRouteKey extends keyof AppRouter['_def']['queries'],
> = inferProcedureOutput<AppRouter['_def']['queries'][TRouteKey]>

export const trpcClient = async (authenticated = true) => {
  const accessToken = authenticated
    ? await auth.currentUser?.getIdToken()
    : null
  return createTRPCClient<AppRouter>({
    url: `${getBaseUrl()}/api/trpc`,
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    },
  })
}
